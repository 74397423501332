<template>
  <div class="non-analysis">
    <header class="non-analysis-header">
      <div class="flex">
        <div class="item">
          <span>商品名：</span>
          <el-input v-model="params.title" placeholder="请输入商品名称"></el-input>
        </div>
        <!-- <div class="item">
          <span>站点：</span>
          <el-input v-model="params.site_name" placeholder="请输入站点名称，如雅虎"></el-input>
        </div> -->
        <div class="item">
          <span>状态：</span>
          <el-select v-model="params.status">
            <el-option value="" label="全部"></el-option>
            <el-option :value="1" label="启用"></el-option>
            <el-option :value="2" label="停用"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="getList">查找</el-button>
        <el-button type="primary" @click="reset">重置</el-button>
      </div>
      <el-button type="primary" @click="$router.push('/market/goodsManager/non_analysis_detail')">添加</el-button>
    </header>
    <DragTable :list-query="list" :header="header">
      <template #status="{row}">{{row.status == 1?'启用':'停用'}}</template>
      <template #get_links="{row}">
        <p v-for="item in row.get_links" :key="item.id"><a :href="item.link" target="_blank" >{{item.link}}</a></p>
      </template>
      <template #ctl="{row}">
        <span class="word-color" @click="ctl(row,1)">{{row.status==1?'停用':'启用'}}</span>
        <span class="word-color" @click="ctl(row,2)">编辑</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        title: '',
        site_name: '',
        status: '',
        size: 10,
        page: 1,
        total: 0
      },
      header: [
        { name: 'ID', value: 'id' },
        { name: '商品名', value: 'title' },
        { name: '商品链接', slot: 'get_links' },
        { name: '商品单价', value: 'price' },
        { name: '商品折扣价', value: 'discount_price' },
        { name: '状态', slot: 'status' },
        { name: '操作', slot: 'ctl' },
      ],
      list: {
        data: []
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios.get(`/marketCenter/platformRequest/goods/list`,{
        params: this.params
      })
      .then(res=>{
        this.list.data = res.data.data
        this.params.total = res.data.total
      })
      
    },
    reset() {
      for(let key in this.params) {
        this.params[key] = ''
      }
      this.params.page = 1
      this.params.size = 10
      this.params.total = 0
      this.getList()
    },
    async ctl(data,type) {
      if(type == 1) {
        const confirm = await this.$goDialog(`确定要${data.status == 1?'停用':'启用'}吗？`)
        if(!confirm) return
        this.stop(data)
      }
      if(type == 2) {
        this.$router.push(`/market/goodsManager/non_analysis_detail?id=${data.id}`)
      }
    },
    stop(data) {
      this.$axios.post(`/marketCenter/platformRequest/goods/set_state`,{
        id: data.id,
        status: data.status == 1 ? 2 : 1
      })
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    handleSizeChange(val){
      this.params.size = val;
      this.params.page = 1
      this.getList();
    },
    handleCurrentChange(val){
      this.params.page = val
      this.getList();
    }
  }
}
</script>
<style lang="scss" scoped>
.word-color{
  margin-right: 15px;
}
.non-analysis-header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  .flex{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .item{
    margin-right: 20px;
    width: 240px;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>